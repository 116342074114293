.home-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}
.home-heading {
  color: #000000;
}

.readonly {
  background-color: #eee;
}

.readonly:active,
.readonly:focus {
  background-color: #eee;
  border: none;
  outline: none;
  box-shadow: none;
}
