.spinning {
  margin-right: 7px;
  top: 2px;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}

.custom-btn:disabled {
  border: none; /* Removes the border */

  color: #d3d3d3; /* Dim grey text, adjust as needed */
}
